import axios from "axios"

import { RequestUtils } from "../../utils"

class ModelAPI {
  getModelList = async params => {
    params = {
      sort: {
        name: "asc",
      },
      ...params,
    }

    return axios.get("api/fashion-models", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getModel = async (id, params) => {
    return axios.get(`api/fashion-models/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModel = async params => {
    return axios.post("api/fashion-models", params)
  }

  editModel = async (id, params) => {
    return axios.put(`api/fashion-models/${id}`, params)
  }

  removeModel = async id => {
    return axios.delete(`api/fashion-models/${id}`)
  }

  getModelPhotos = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/photos`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  uploadModelPhoto = async (id, params, onUploadProgress) => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/fashion-models/${id}/photos`, formData, {
      onUploadProgress,
    })
  }

  updateModelPhoto = async (attachmentId, params) => {
    let formData = new FormData()
    formData.append("_method", "PUT")
    Object.entries(params).forEach(entry => {
      const [key, value] = entry
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })
    return axios.post(`api/fashion-models/photos/${attachmentId}`, formData)
  }

  removeModelPhoto = async attachmentId => {
    return axios.delete(`api/fashion-models/photos/${attachmentId}`)
  }

  removeModelPhotos = async photos => {
    return axios.delete(`api/fashion-models/photos`, { data: { photos } })
  }

  removeModelAvatar = async id => {
    return axios.delete(`api/fashion-models/${id}/avatar`)
  }

  getModelRatingsData = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/ratings`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelRating = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/ratings`, params)
  }

  getModelHistory = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/histories`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getModelFeatures = async id => {
    return axios.get(`api/fashion-models/${id}/profile`)
  }

  editModelFeatures = async (id, params) => {
    return axios.put(`api/fashion-models/${id}/profile`, params)
  }

  // Model logistics
  getModelLogistics = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/logistics`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelLogistics = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/logistics`, params)
  }

  getModelLogistic = async (id, params) => {
    return axios.get(`api/fashion-models/logistics/${id}`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  updateModelLogistics = async (id, params) => {
    return axios.put(`api/fashion-models/logistics/${id}`, params)
  }

  removeModelLogistics = async id => {
    return axios.delete(`api/fashion-models/logistics/${id}`)
  }

  addLogisticExpenses = async (id, params) => {
    return axios.post(`api/fashion-models/logistics/${id}/expenses`, params)
  }
  // --- //

  getModelDocuments = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/documents`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelDocuments = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/documents`, params)
  }

  getModelDocument = async id => {
    return axios.get(`api/fashion-models/documents/${id}`)
  }

  updateModelDocument = async (id, params) => {
    return axios.put(`api/fashion-models/documents/${id}`, params)
  }

  removeModelDocument = async id => {
    return axios.delete(`api/fashion-models/documents/${id}`)
  }

  uploadFileForNewDocument = async params => {
    let formData = new FormData()
    formData.append("file", params.file)

    return axios.post(`api/fashion-models/documents/files`, formData)
  }

  uploadFileForDocument = async (documentId, params) => {
    let formData = new FormData()
    formData.append("file", params.file)

    return axios.post(
      `api/fashion-models/documents/${documentId}/files`,
      formData
    )
  }

  removeDocumentFile = async id => {
    return axios.delete(`api/fashion-models/documents/files/${id}`)
  }

  getModelWork = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/work`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelWork = async (id, params) => {
    let formData = new FormData()

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/fashion-models/${id}/work`, formData)
  }
  updateModelWork = async (id, params) => {
    let formData = new FormData()

    formData.append("_method", "PUT")

    Object.entries(params).forEach(entry => {
      const [key, value] = entry

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[${i}]`, value[i])
        }
      } else {
        formData.append(key, value)
      }
    })

    return axios.post(`api/fashion-models/work/${id}`, formData)
  }
  removeModelWork = async id => {
    return axios.delete(`api/fashion-models/work/${id}`)
  }

  // Model videos
  getModelVideos = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/videos`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelVideo = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/videos`, params)
  }

  getModelVideo = async id => {
    return axios.get(`api/fashion-models/videos/${id}`)
  }

  updateModelVideo = async (id, params) => {
    return axios.put(`api/fashion-models/videos/${id}`, params)
  }

  removeModelVideo = async id => {
    return axios.delete(`api/fashion-models/videos/${id}`)
  }

  removeModelVideos = async videos => {
    return axios.delete(`api/fashion-models/videos`, { data: { videos } })
  }
  // --- //

  // Model agencies
  getModelAgencies = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/agencies`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelAgency = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/agencies`, params)
  }

  getModelAgency = async id => {
    return axios.get(`api/fashion-models/agencies/${id}`)
  }

  updateModelAgency = async (id, params) => {
    return axios.put(`api/fashion-models/agencies/${id}`, params)
  }

  removeModelAgency = async id => {
    return axios.delete(`api/fashion-models/agencies/${id}`)
  }
  // --- //

  // Model videos
  getModelBooks = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/books`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  addModelBook = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/books`, params)
  }

  getModelBook = async id => {
    return axios.get(`api/fashion-models/books/${id}`)
  }

  updateModelBook = async (id, params) => {
    return axios.put(`api/fashion-models/books/${id}`, params)
  }

  removeModelBook = async id => {
    return axios.delete(`api/fashion-models/books/${id}`)
  }
  // --- //

  // Model activities
  getModelActivities = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/activities`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // Model statements
  // get
  getModelStatements = async (id, params) => {
    return axios.get(`api/fashion-models/${id}/statements`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // Pay model statements
  paidModelStatements = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/statements/paid`, params)
  }

  // Pay model statement
  paidModelStatement = async (id, statement) => {
    return axios.post(`api/fashion-models/${id}/statements/${statement}/paid`)
  }

  // Unpaid model statement
  unpaidModelStatement = async (id, statement) => {
    return axios.post(`api/fashion-models/${id}/statements/${statement}/unpaid`)
  }
  // --- //

  // Model Groups
  removeModelFromGroup = async (modelId, groupId) => {
    return axios.delete(`api/fashion-models/${modelId}/groups/${groupId}`)
  }

  getModelGroups = async (modelId, params) => {
    return axios.get(`api/fashion-models/${modelId}/groups`, {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  // --- //

  // Events email send
  eventsEmailSend = async (id, params) => {
    return axios.post(`api/fashion-models/${id}/events/send-email`, params)
  }
  // --- //
}

export const model = new ModelAPI()
