import React, { useState } from "react"

import { EVENTS_CALENDAR_DEFAULT_CONFIG } from "consts"

export const useEventsCalendar = ({ config: configData = null }) => {
  const [config, setConfig] = useState({
    ...EVENTS_CALENDAR_DEFAULT_CONFIG,
    ...configData,
  })

  const updateConfig = data => {
    setConfig(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  return {
    config,
    updateConfig,
  }
}
